<template>
  <div class="main">
    <div class="search-box">
      <van-search @input="toSearch" v-model="queryPostData.searchName" clearable placeholder="请输入用户昵称" />
    </div>
    <div class="table-title-box">
      <div class="name-cell-box">观众</div>
      <div class="other-cell-box-1">观看时长</div>
      <div class="other-cell-box-2">所在地</div>
    </div>
    <div class="list-box-main">
      <van-list
              v-model="loading"
              :finished="finished"
              :immediate-check="false"
              finished-text="没有更多信息了"
              @load="onLoad"
      >
        <div v-for="item in list" :key="item.id" class="table-line-box">
          <div class="name-cell-box">{{item.name}}</div>
          <div class="other-cell-box-1">{{item.watchTime}}</div>
          <div class="other-cell-box-2">{{item.ipAddress}}</div>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
import { getUserList } from '@/api/live'
import { Toast } from 'vant';
import Cookies from "js-cookie";
export default {
  name: 'Keyword',
  components: {
  },
  data() {
    return {
      userId: '', // 用户id
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      total:0,
      disableType:false,
      type:1,
      queryPostData: {
        livingId: '',
        userId: '',
        searchName: '',
        page: 1,
        pageSize: 30
      },
    }},
  created() {
    const query = this.$route.query
    if(!query.liveId){
      Toast('参数错误')
      return;
    }
    let userId=Cookies.get('userId')
    if(!userId){
      this.$router.push({ name: 'liveList', query: {}});
      return
    }
    this.queryPostData.livingId = query.liveId
    this.queryPostData.userId = userId
    this.onLoad()
  },
  mounted:function(){
  },
  methods: {
    onLoad() {
      this.getData()
      // 加载状态结束
      this.loading = false;
      this.refreshing = false;
    },
    // 搜索
    toSearch(){
      this.queryPostData.page = 1
      this.list = []
      this.getData()
    },
    // 获取文章 图片等
    getData() {
      if(this.disableType === true){
        return false
      }
      Toast.loading({message: '加载中...', forbidClick: true, duration: 0});
      this.disableType = true;
      getUserList(this.queryPostData).then(response => {
        Toast.clear()
        this.disableType=false;
        let nowPage=this.queryPostData.page
        this.queryPostData.page=nowPage+1
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].watchTime = this.formatSeconds(response.data[i].watchTime)
          this.list.push(response.data[i]);
        }
        if(this.queryPostData.pageSize>response.data.length){
          this.finished = true;
        }
      })
    },
    //秒数转化为时分秒
    formatSeconds(value) {
      var secondTime = parseInt(value);// 秒
      var minuteTime = 0;// 分
      var hourTime = 0;// 小时
      if(secondTime > 60) {//如果秒数大于60，将秒数转换成整数
        //获取分钟，除以60取整数，得到整数分钟
        minuteTime = parseInt(secondTime / 60);
        //获取秒数，秒数取余，得到整数秒数
        secondTime = parseInt(secondTime % 60);
        //如果分钟大于60，将分钟转换成小时
        if(minuteTime > 60) {
          //获取小时，获取分钟除以60，得到整数小时
          hourTime = parseInt(minuteTime / 60);
          //获取小时后取余的分，获取分钟除以60取余的分
          minuteTime = parseInt(minuteTime % 60);
        }
      }
      var result = "" + parseInt(secondTime) + "秒";
      if(minuteTime > 0) {
        result = "" + parseInt(minuteTime) + "分" + result;
      }
      if(hourTime > 0) {
        result = "" + parseInt(hourTime) + "小时" + result;
      }
      console.log('result',result);
      return result;
    }
  }
}
</script>
<style scoped>
  .main{
    background-color:#ffffff;
  }
  .list-box-main{
    background-color:#ffffff;
    min-height: 100vh;
  }
  .table-title-box{
    display: flex;
    justify-content: space-between;
    color: #969799;
    font-size: 12px;
    background-color: #f8f7f7;
    padding: 20px;
    border-bottom: 1px solid #E5E5E5;
  }
  .table-line-box{
    display: flex;
    justify-content: space-between;
    color: #000000;
    font-size: 12px;
    padding: 20px;
    border-bottom: 1px solid #E5E5E5;
  }
  .name-cell-box{
    width: calc(50vw - 20px);
  }
  .other-cell-box-1{
    width: calc(20vw - 20px);
    text-align: center;
  }
  .other-cell-box-2{
    width: calc(30vw - 20px);
    text-align: center;
  }
  .bottom-margin{
    height: 100px;
  }
  .search-box{
    padding-top: 10px;
  }
</style>
